$primary: #1C4B8B;
$secondary: #E52322;
$success: #0dc900;

$links_primary: #FFF;

body {
    overflow: hidden;
}

.menu-icon {
    font-size: 20px;
    color: $primary;
}

.nav-list {
    list-style: none;

    li {
        :hover {
            background-color: #DEEAF7;
            border-radius: 5px;
            transition: 0.3s;
        }
    }
}

.header-list {
    list-style: none;

    li {
        :hover {
            background-color: #DEEAF7;
            border-radius: 5px;
            transition: 0.3s;
        }
    }
}

.nav-list.mobile {
    list-style: none;
    color: white;

    li {
        font-weight: bold;
        :hover {
            background-color: $secondary;
            border-radius: 5px;
            transition: 0.3s;
        }
    }

    i {
        color: white;
    }
}

.nav-links-footer, .primary-link {
    font-size: 14px;
    color: $primary;
    transition: 0.2s;
}

.nav-links-footer:hover, .primary-link:hover {
    color: lighten($primary, 20%);
}

.links {
    color: $links_primary;
    text-decoration: underline;

    :hover {
        color: darken($links_primary, 10%);
    }
}

.form-success-message {
    color: $success;
    background-color: lighten($success, 50%);
    border-color: $success;
    border-width: 1px;
    font-weight: bold;
    padding: 5px;
    border-radius: 5px;
}

.form-error-message {
    color: $secondary;
    background-color: lighten($secondary, 35%);
    border-color: $secondary;
    border-width: 1px;
    font-weight: bold;
    padding: 5px;
    border-radius: 5px;
}

.checkbox-custom {
    padding: 7px 0;
    width: 100%;
    cursor: pointer;
    background-color: transparent;
    border: 2px solid $primary;
    text-align: center;
    color: $primary;
    font-weight: bold;
    font-size: 16px;
    border-radius: 5px;
}

.checkbox-custom-input {
    display: none;
}

.checkbox-custom-input:checked + .checkbox-custom {
    background-color: lighten($primary, 45%);
}

.client-info-box {
    background-color: lighten($primary, 63%);
    border: 2px solid $primary;
    // color: $primary;
    border-radius: 5px;
    padding: 5px 10px;
}

.file-uploader {
    background-color: lighten(gray, 65%);
    border: 2px solid gray;
    border-radius: 4px;
    padding: 5px;
    cursor: pointer;
    width: 100%;

    &:hover {
        background-color: lighten(gray, 40%);
    }
}

.review-day {
    color: gray;
    font-size: 24px;
}

.review-day.review-day-selected {
    color: green;
}

.file-news {
    background-color: #DFEAFA;
    padding: 10px;
    border-radius: 5px;
    border-width: 3px;
    border-style: solid;
    border-color: #1c4b8b;
    width: 90%;
}

.file-news-input::file-selector-button {
    color: black;
    background: #fff;
    border-radius: 5px;
    border-width: 2px;
    margin-right: 15px;
}

/* CheckMark */
$size: 100%;
$color: $success;

svg {
  display: block;
  height: $size;
  width: $size;
  color: $color; /* SVG path use currentColor to inherit this */
}

.circle {
  stroke-dasharray: 76;
  stroke-dashoffset: 76;
  animation: draw 1s forwards;
}

.tick {
  stroke-dasharray: 18;
  stroke-dashoffset: 18;
  animation: draw 1s forwards 1s;
  position: absolute;
}

@keyframes draw { 
  to { stroke-dashoffset: 0 } 
}
/* End CheckMark */

#newsHTML {
    all:revert;

    * {
        all: revert;
    }
}

.ck .ck-content {
    * {
        all: revert;
    }
}